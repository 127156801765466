// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$E-Invoice-UI-primary: mat.define-palette(mat.$indigo-palette);
$E-Invoice-UI-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$E-Invoice-UI-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$E-Invoice-UI-theme: mat.define-light-theme(
  (
    color: (
      primary: $E-Invoice-UI-primary,
      accent: $E-Invoice-UI-accent,
      warn: $E-Invoice-UI-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($E-Invoice-UI-theme);

@tailwind base;
@tailwind components;
@tailwind utilities;
@import './styles//variables/colors.scss';
@import '~@ng-select/ng-select/themes/default.theme.css';

/* You can add global styles to this file, and also import other style files */
body {
  padding: 0;
  margin: 0;
  font:
    400 14px/20px 'Roboto',
    sans-serif;
  color: #323232;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

button:disabled {
  opacity: 0.5;
}

img {
  max-width: initial;
  height: auto;
}

.global-content-section {
  width: calc(100% - 231px);
  margin-left: 230px;
}

.dashboard-layout {
  @apply flex justify-between items-end;
}

.display-flex {
  @apply flex;
}

.w-100 {
  @apply w-full;
}

.h-100 {
  @apply h-full;
}

.center-a-container {
  @apply flex justify-center items-center;
}

.arrange-to-ends {
  @apply flex ml-auto;
}

.rounded-icon {
  @apply size-3 rounded-full;
}

// colors
.bgcolor-green {
  background-color: $color-green;
}

.bgcolor-red {
  background-color: $color-red;
}

.bgcolor-gray {
  background-color: $color-gray;
}

.bgcolor-blue {
  background-color: $color-blue;
}

.bgcolor-orange {
  background-color: $color-orange;
}
// end of colors

.vertical-center {
  @apply flex items-center;
}

.align-right-end {
  margin-right: 10px;
  margin-left: auto;
}

.take-available-space {
  @apply flex flex-1;
  position: relative;
  top: 69px;
}

.invalid-login-page {
  flex: none;
}

.active {
  background-color: $color-blue-300;
  color: $color-white;
}

.inactive {
  color: $color-gray-300;
}

.reject-reason-input {
  margin-top: 10px;
  height: 80px;
  width: 100%;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .remarks-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 270px;
  min-width: 275px;
  display: inline-block;
}

.tooltip .tooltip-text {
  visibility: hidden;
  max-width: 275px;
  border-radius: 4px;
  background-color: $color-gray;
  color: $color-white-alpha-120;
  text-align: left;
  padding: 4px 4px 4px 8px;
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 1px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
  white-space: initial;
  word-wrap: break-word;
}

.tooltip .last-tooltip-text {
  bottom: 24px;
  top: auto;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

// status card styles
.status-container {
  display: flex;
  width: fit-content;
  margin: 14px;
  background: $color-white;
  box-shadow: 0px 0px 5.5px 0px rgba(228, 226, 226, 0.25);
}

.status-bar {
  border: 6px solid;
}

.status-content {
  padding: 7px 10px;
  flex-grow: 1;
}

.status-card-dropdown {
  margin: 0;
  padding: 0 0 0 6px;
  border: none;
  cursor: pointer;
}

.dropdown-icon {
  margin-left: 5px;
  font-size: 12px;
  cursor: pointer;
}

.divider {
  margin: 6px 0px;
  border: none;
  border-top: 1px solid $color-gray-10;
}

.invoice-count {
  font-size: 24px;
  text-align: center;
}

.invoice-count span {
  font-size: 18px;
}

.approved-or-submitted {
  color: $color-orange-flush !important;
}

.rej-invalid-cancelled {
  color: $color-maroon-flush !important;
}

.ready-for-review {
  color: $color-thick-blue !important;
}

.validated {
  color: $color-green-chateau !important;
}

.failed {
  color: $color-maroon-flush !important;
}
// toastr notification styles
.toast-success,
.toast-error,
.toast-warning,
.toast-info {
  background-color: $color-blue-10 !important;
  color: $color-green-50 !important;
  background-image: none !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  background-image: url('assets/cross-mark.svg') !important;
  background-position: 312px 17px !important;
  background-repeat: no-repeat !important;
}

.toast-top-center {
  top: 130px !important;
}

.toast-container .ngx-toastr {
  padding: 17px 44px 20px 20px !important;
  box-shadow: none !important;
  border: 1px solid $color-blue-200 !important;
  border-radius: 8px !important;
  width: 356px !important;
}

.date-range-picker {
  display: flex !important;
}

.date-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
}

.mat-form-field-type-mat-date-range-input .mat-form-field-infix {
  margin-top: -14px;
  height: 34px;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding: 4px 0px 0px 4px;
  width: 100%;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  width: 0px;
}

.mat-form-field {
  height: 36px;
  width: 100%;
  font-size: 14px;
  color: $color-black-alpha-80;
  border: 1px solid $color-black-alpha-16;
  border-radius: 4px;
}

.mat-datepicker-toggle {
  color: $color-dark-blue;
  font-size: 1px;
}

.mat-end-date::-moz-focus-inner {
  border: none;
  outline: none;
}

.mat-date-range-input-start-wrapper input:focus,
.mat-date-range-input-end-wrapper input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.mat-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mat-calendar {
  background-color: $color-white;
}

.ng-select.ng-select-multiple .ng-select-container {
  width: 100%;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  height: 30px !important;
  flex-wrap: nowrap !important;
  overflow-x: auto !important;
  min-width: 150px !important;
  scrollbar-width: none;
  width: 150px;
}

.mat-datepicker-toggle .mat-button-wrapper svg {
  fill: $color-blue-300;
}
