// White
$color-white: #ffffffff;
$color-white-alpha-120: #ffffff;
$color-white-alpha-80: #ffffffcc;
$color-white-alpha-40: #ffffff66;
$color-white-alpha-24: #ffffff3d;
$color-white-alpha-12: #ffffff1f;
$color-white-alpha-8: #ffffff14;
$color-white-alpha-4: #ffffff0a;
$color-white-alpha-2: #ffffff05;

// Black
$color-black: #000000;
$color-black-alpha-80: #000000cc;
$color-black-alpha-50: #00000080;
$color-black-alpha-40: #00000066;
$color-black-alpha-16: #00000029;
$color-black-alpha-12: #0000001f;
$color-black-alpha-8: #00000014;
$color-black-alpha-4: #0000000a;
$color-black-beta-80: #151718;

// Yellow
$color-yellow-alpha-90: #ffffe0;

// Silver
$color-silver: #797979;
$color-silver-alpha-4: #c4c4c4;
$color-silver-alpha-10: #a7a8a9;

//Gray
$color-gray: #535759;
$color-gray-alpha-4: #00000066;
$color-gray-10: #f0f0f0;
$color-gray-100: #e9e9e9;
$color-gray-200: #d6d4d4;
$color-gray-300: #54575a;
$color-gray-alpha-50: #d4d6d6;
$color-silver: #797979;
$color-light-gray: #dddddd;
$color-gray-400: #d1d1d1;
$color-gray-500: #929292;
$color-gray-600: #5b6176;
$color-gray-700: #f8f8f8;
$color-gray-800: #a9a9ac;

// Blue
$color-blue-10: #ebf3fe;
$color-blue: #024096;
$color-dark-blue: #00008b;
$color-thick-blue: #004098;
$color-blue-200: #7ab2e3;
$color-blue-300: #057fe5;
$color-blue-500: #004098;
$color-alice-blue: #ebf3ff;

// Green
$color-green: #02a053;
$color-green-chateau: #00a251;
$color-green-50: #234735;
$color-green-300: #28a745;

// Red
$color-red: #b1003c;
$color-red-alpha-4: #ff0000;

// Orange
$color-orange: #ff850b;
$color-orange-flush: #ff850a;

// Maroon
$color-maroon-flush: #b1003c;
